<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px !important;">-->
    <div class="col-grow flex justify-between h-full">
      <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
      <e-window-table class="col-grow h-full"
                      :columns="['ID', 'Login', 'Último acesso', 'Status', 'Opções']"
      >
        <e-tr v-for="(data, index) in usuarios" :key="index">
          <e-td><span>{{ String(data.id).padStart(4, '0') }}</span></e-td>
          <e-td>{{ data.username }} <span v-if="data.config && data.config.responsavelNome" class="font-thin m-l-xs">{{data.config.responsavelNome}} &lt;{{data.config.responsavelEmail}}&gt;</span> </e-td>
          <e-td><span v-if="data.lastLogin">{{ data.lastLogin.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span><span v-else>Nunca</span>
          </e-td>
          <e-td>
            <div v-if="data.active" class="flex items-center text-positive">
              <e-btn-circle-status status="positive" class="sm m-r-xs" />
              Ativo
            </div>
            <div v-else>
              <e-btn-circle-status status="negative" class="sm m-r-xs" />
              Inativo
            </div>
          </e-td>
          <e-td>
            <a @click="open(data.id)"><i class="erp-icon search min"></i> </a></e-td>
        </e-tr>
        <e-tr v-if="!usuarios || !usuarios.length">
          <e-td style="text-align: left !important;" colspan="100%">
            Nenhum registro
          </e-td>
        </e-tr>
      </e-window-table>
      <e-btn label="Criar acesso" class="m-t" @click="novo" />
    </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable, EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import windowUsuario from '../../../usuario/window/windowUsuarioShow'

export default {
  name: 'Acesso',
  inject: ['container'],
  props: {
    comitente: {
      required: true
    },
    router: {required: false}
  },
  components: {
    EWindowTable,
    ETr,
    ETd,
    EBtnCircleStatus
  },
  data () {
    return {}
  },
  computed: {
    usuarios () {
      let usuarios = []
      if(this.comitente.usuarios && this.comitente.usuarios.length > 0){
        this.comitente.usuarios.map(usuario => {
          usuarios.push(usuario.usuario)
        })
      }
      return usuarios
    }
  },
  methods: {
    status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    },
    open (id) {
      this.windowUsuario(id, null, 'ROLE_COMITENTE', null, true)
    },
    novo () {
      this.windowUsuario(null, null, 'ROLE_COMITENTE', this.comitente.pessoa.id, true)
    },
    load () {
      this.container && this.container.load && this.container.load()
    },
    windowUsuario: windowUsuario
  }
}
</script>
